import React, { Component } from "react";
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import PropTypes from 'prop-types';
import moment from 'moment';
//import CreditReport from "components/CreditReport/CreditReport.js";
import DerogatoryReport from "components/DerogatoryReport/DerogatoryReport.js";
import { BrowserRouter, Route } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import Badge from "components/Badge/Badge.js";
//import Link from '@material-ui/core/Link';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosSharpIcon from '@material-ui/icons/ArrowBackIosSharp';
import CreditReportHeatMap from "components/Charts/CreditReportHeatMap.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';
import TMAMHeatMapPropertyTenant from "components/Charts/TMAMHeatMapPropertyTenant.js";
import DerogatoryHeatMap from 'components/Charts/DerogatoryReportHeatMap.js';
import PirsReportHeatMap from 'components/Charts/PirsReportHeatMap.js';
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import {
    whiteColor,
    hexToRgb,
    megaColor,
    lyticsColor
  } from "assets/jss/material-dashboard-pro-react.js";

import TMAMCreditReportHeatMap from "./TMAMCreditReportHeatMap";
import TMAMDerogatoryReportHeatMap from "./TMAMDerogatoryReportHeatMap";
import TMAMPirsReportHeatMap from "./TMAMPirsReportHeatMap";
 
const TMAMHeatMapTenantRisk = ({user,labelStatus,tenantName,tenantAddress,city,state,zip,tmTenantsOverAlCredPirsDeroScoreRequest,tenantsOverAlCredPirsDeroScore,tenantId,source,fundId,fundName,propertyId,propertyName,selectedRangeFrom,selectedRangeTo,month,year,category,
  selectedFundName,selectedFundManagerName}) =>{
  const useStyles = makeStyles(styles);
  const history = useHistory();
  const classes = useStyles(); 
    const [scoreTypeValue, setScoreTypeValue] = React.useState(34);
    const [monthValue, setMonthValue] = React.useState(45);
    const [pirsEnabled, setPirsEnabled] = React.useState(false);
    const [scoreLabels, setScoreLabels] = React.useState(true);
    const[showBackNavigation,setShowBackNavigation]=React.useState(false);
    const [userId, setUserId] = React.useState(user.id);
    const [showPirsHeatMap, setShowPirsHeatMap] = React.useState(false);
    const [showDerogatoryHeatMap, setShowDerogatoryHeatMap] = React.useState(false);
    const [showCreditReportHeatMap, setShowCreditReportHeatMap] = React.useState(false);
    const [monthNameValue, setMonthNameValue] = React.useState("");
    const [pirsMonthNameValue, setPirsMonthNameValue] = React.useState("");

    const [monthNameValue1, setMonthNameValue1] = React.useState(moment().format('MMM').toLowerCase());

    const [scoreCategoryValue, setScoreCategoryValue] = React.useState("");

    const [monthNumber, setMonthNumber] = React.useState("");
    const [scoreType, setSetscoreType] = React.useState("");
    const [year1, setYear1] = React.useState(moment().format('YYYY'));
    const [year2,setYear2]= React.useState("");


    const [organisationId, setOrganisationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
    //const [selectedOrganization, setSelectedOrganization] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgName')));

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);

   // const [pirsEnabled, setPirsEnabled] = React.useState(false);
   const[showFundDetails,setShowFundDetails]=React.useState(false);
   
   var now = moment().subtract(1, "month").format('MMMM').toUpperCase();
   var monthArray=[];
   var yearArray=[];
   var selectedYear="";
   var selectedMonth="";
  
   var currentMonth=moment().format('MMMM').toLowerCase().substring(0,3);
   var currMonthName  =  moment().subtract(1, "month").format('MMMM').toLowerCase().substring(0,3);

   var currentYear  = moment().format('YYYY');
   var prev1Year  = moment().subtract(1, "year").format('YYYY');

   var prev1MonthName  = moment().subtract(2, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev2MonthName  = moment().subtract(3, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev3MonthName  = moment().subtract(4, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev4MonthName  = moment().subtract(5, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev5MonthName  = moment().subtract(6, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev6MonthName  = moment().subtract(7, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev7MonthName  = moment().subtract(8, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev8MonthName  = moment().subtract(9, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev9MonthName  = moment().subtract(10, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev10MonthName  = moment().subtract(11, "month").format('MMMM').toLowerCase().substring(0,3);
   var prev11MonthName  = moment().subtract(12, "month").format('MMMM').toLowerCase().substring(0,3);
  
   var pirsCurrMonthName  =  moment().subtract(1, "month").format('MMMM').toUpperCase();
   var pirsPrev1MonthName  = moment().subtract(2, "month").format('MMMM').toUpperCase();
   var  pirsPrev2MonthName  = moment().subtract(3, "month").format('MMMM').toUpperCase();
   var  pirsPrev3MonthName  = moment().subtract(4, "month").format('MMMM').toUpperCase();
   var  pirsPrev4MonthName  = moment().subtract(5, "month").format('MMMM').toUpperCase();
   var  pirsPrev5MonthName  = moment().subtract(6, "month").format('MMMM').toUpperCase();
   var  pirsPrev6MonthName  = moment().subtract(7, "month").format('MMMM').toUpperCase();
   var  pirsPrev7MonthName  = moment().subtract(8, "month").format('MMMM').toUpperCase();
   var  pirsPrev8MonthName  = moment().subtract(9, "month").format('MMMM').toUpperCase();
   var  pirsPrev9MonthName  = moment().subtract(10, "month").format('MMMM').toUpperCase();
   var  pirsPrev10MonthName  = moment().subtract(11, "month").format('MMMM').toUpperCase();
   var  pirsPrev11MonthName  = moment().subtract(12, "month").format('MMMM').toUpperCase();
  
  
console.log("yeaar"+prev1Year);

   const setYearAndMonth=(month2)=>{

   if(currMonthName=="jan"){

    if(month2=="jan"){
      selectedYear = currentYear;
      }else if(month2=="dec"){
        selectedYear = prev1Year;
      }else if(month2=="nov"){
      selectedYear = prev1Year;
      }else if(month2=="oct"){
      selectedYear = prev1Year;
      }else if(month2=="sep"){
      selectedYear = prev1Year;
      }else if(month2=="aug"){
      selectedYear = prev1Year;
      }else if(month2=="jul"){
      selectedYear = prev1Year;
      }else if(month2=="jun"){
      selectedYear = prev1Year;
      }else if(month2=="may"){
      selectedYear = prev1Year;
      }else if(month2=="apr"){
      selectedYear = prev1Year;
      }else if(month2=="mar"){
      selectedYear = prev1Year;
      }else if(month2=="feb"){
      selectedYear = prev1Year;
      }
      setYear2(selectedYear);
    }
    
    else if(currMonthName=="feb"){
    
    if(month2=="feb"){
     selectedYear = currentYear;
    }else if(month2=="jan"){
    selectedYear = currentYear;
    }else if(month2=="dec"){
    selectedYear = prev1Year;
    }else if(month2=="nov"){
    selectedYear = prev1Year;
    }else if(month2=="oct"){
    selectedYear = prev1Year;
    }else if(month2=="sep"){
    selectedYear = prev1Year;
    }else if(month2=="aug"){
    selectedYear = prev1Year;
    }else if(month2=="jul"){
    selectedYear = prev1Year;
    }else if(month2=="jun"){
    selectedYear = prev1Year;
    }else if(month2=="may"){
    selectedYear = prev1Year;
    }else if(month2=="april"){
    selectedYear = prev1Year;
    }else if(month2=="mar"){
    selectedYear = prev1Year;
    }
    setYear2(selectedYear);
  
  }
    else if(currMonthName=="mar"){
    
    if(month2=="mar"){
    selectedYear = currentYear;
    }else if(month2=="feb"){
    selectedYear = currentYear;
    }else if(month2=="jan"){
    selectedYear = currentYear;
    }else if(month2=="dec"){
    selectedYear = prev1Year;
    }else if(month2=="nov"){
    selectedYear = prev1Year;
    }else if(month2=="oct"){
    selectedYear = prev1Year;
    }else if(month2=="sep"){
    selectedYear = prev1Year;
    }else if(month2=="aug"){
    selectedYear = prev1Year;
    }else if(month2=="jul"){
    selectedYear = prev1Year;
    }else if(month2=="jun"){
    selectedYear = prev1Year;
    }else if(month2=="may"){
    selectedYear = prev1Year;
    }else if(month2=="apr"){
    selectedYear = prev1Year;
    }
    setYear2(selectedYear);
    }

    else if(currMonthName=="apr"){
    
      if(month2=="apr"){
      selectedYear = currentYear;
      }else if(month2=="mar"){
      selectedYear = currentYear;
      }else if(month2=="feb"){
      selectedYear = currentYear;
      }else if(month2=="jan"){
      selectedYear = currentYear;
      }else if(month2=="dec"){
      selectedYear = prev1Year;
      }else if(month2=="nov"){
      selectedYear = prev1Year;
      }else if(month2=="oct"){
      selectedYear = prev1Year;
      }else if(month2=="sep"){
      selectedYear = prev1Year;
      }else if(month2=="aug"){
      selectedYear = prev1Year;
      }else if(month2=="jul"){
      selectedYear = prev1Year;
      }else if(month2=="jun"){
      selectedYear = prev1Year;
      }else if(month2=="may"){
      selectedYear = prev1Year;
      }
      setYear2(selectedYear);
      }

      else if(currMonthName=="may"){
    
        if(month2=="may"){
        selectedYear = currentYear;
        }else if(month2=="apr"){
        selectedYear = currentYear;
        }else if(month2=="mar"){
        selectedYear = currentYear;
        }else if(month2=="feb"){
        selectedYear = currentYear;
        }else if(month2=="jan"){
        selectedYear = currentYear;
        } else if(month2=="dec"){
        selectedYear = prev1Year;
        }else if(month2=="nov"){
        selectedYear = prev1Year;
        }else if(month2=="oct"){
        selectedYear = prev1Year;
        }else if(month2=="sep"){
        selectedYear = prev1Year;
        }else if(month2=="aug"){
        selectedYear = prev1Year;
        }else if(month2=="jul"){
        selectedYear = prev1Year;
        }else if(month2=="jun"){
        selectedYear = prev1Year;
        }
        setYear2(selectedYear);
        }
        else if(currMonthName=="jun"){
    
          if(month2=="jun"){
          selectedYear = currentYear;
          }else if(month2=="may"){
          selectedYear = currentYear;
          }else if(month2=="apr"){
          selectedYear = currentYear;
          }else if(month2=="mar"){
          selectedYear = currentYear;
          }else if(month2=="feb"){
          selectedYear = currentYear;
          }else if(month2=="jan"){
          selectedYear = currentYear;
          } else if(month2=="dec"){
          selectedYear = prev1Year;
          }else if(month2=="nov"){
          selectedYear = prev1Year;
          }else if(month2=="oct"){
          selectedYear = prev1Year;
          }else if(month2=="sep"){
          selectedYear = prev1Year;
          }else if(month2=="aug"){
          selectedYear = prev1Year;
          }else if(month2=="jul"){
          selectedYear = prev1Year;
          }
          setYear2(selectedYear);
          }

          else if(currMonthName=="jul"){
    
            if(month2=="jul"){
            selectedYear = currentYear;
            }else if(month2=="jun"){
            selectedYear = currentYear;
            }else if(month2=="may"){
            selectedYear = currentYear;
            }else if(month2=="apr"){
            selectedYear = currentYear;
            }else if(month2=="mar"){
            selectedYear = currentYear;
            }else if(month2=="feb"){
            selectedYear = currentYear;
            }else if(month2=="jan"){
            selectedYear = currentYear;
            } else if(month2=="dec"){
            selectedYear = prev1Year;
            }else if(month2=="nov"){
            selectedYear = prev1Year;
            }else if(month2=="oct"){
            selectedYear = prev1Year;
            }else if(month2=="sep"){
            selectedYear = prev1Year;
            }else if(month2=="aug"){
            selectedYear = prev1Year;
            }
            setYear2(selectedYear);
            }

            else if(currMonthName=="aug"){
    
              if(month2=="aug"){
              selectedYear = currentYear;
              }else if(month2=="jul"){
              selectedYear = currentYear;
              }else if(month2=="jun"){
              selectedYear = currentYear;
              }else if(month2=="may"){
              selectedYear = currentYear;
              }else if(month2=="apr"){
              selectedYear = currentYear;
              }else if(month2=="mar"){
              selectedYear = currentYear;
              }else if(month2=="feb"){
              selectedYear = currentYear;
              }else if(month2=="jan"){
              selectedYear = currentYear;
              } else if(month2=="dec"){
              selectedYear = prev1Year;
              }else if(month2=="nov"){
              selectedYear = prev1Year;
              }else if(month2=="oct"){
              selectedYear = prev1Year;
              }else if(month2=="sep"){
              selectedYear = prev1Year;
              }
              setYear2(selectedYear);
              }

              else if(currMonthName=="sep"){
    
                if(month2=="sep"){
                selectedYear = currentYear;
                }else if(month2=="aug"){
                selectedYear = currentYear;
                }else if(month2=="jul"){
                selectedYear = currentYear;
                }else if(month2=="jun"){
                selectedYear = currentYear;
                }else if(month2=="may"){
                selectedYear = currentYear;
                }else if(month2=="apr"){
                selectedYear = currentYear;
                }else if(month2=="mar"){
                selectedYear = currentYear;
                }else if(month2=="feb"){
                selectedYear = currentYear;
                }else if(month2=="jan"){
                selectedYear = currentYear;
                } else if(month2=="dec"){
                selectedYear = prev1Year;
                }else if(month2=="nov"){
                selectedYear = prev1Year;
                }else if(month2=="oct"){
                selectedYear = prev1Year;
                }
                setYear2(selectedYear);
                }

                else if(currMonthName=="oct"){
    
                  if(month2=="oct"){
                  selectedYear = currentYear;
                  }else if(month2=="sep"){
                  selectedYear = currentYear;
                  }else if(month2=="aug"){
                  selectedYear = currentYear;
                  }else if(month2=="jul"){
                  selectedYear = currentYear;
                  }else if(month2=="jun"){
                  selectedYear = currentYear;
                  }else if(month2=="may"){
                  selectedYear = currentYear;
                  }else if(month2=="apr"){
                  selectedYear = currentYear;
                  }else if(month2=="mar"){
                  selectedYear = currentYear;
                  }else if(month2=="feb"){
                  selectedYear = currentYear;
                  }else if(month2=="jan"){
                  selectedYear = currentYear;
                  } else if(month2=="dec"){
                  selectedYear = prev1Year;
                  }else if(month2=="nov"){
                  selectedYear = prev1Year;
                  }
                  setYear2(selectedYear);
                  }

                  else if(currMonthName=="nov"){
    
                    if(month2=="nov"){
                    selectedYear = currentYear;
                    }else if(month2=="oct"){
                    selectedYear = currentYear;
                    }else if(month2=="sep"){
                    selectedYear = currentYear;
                    }else if(month2=="aug"){
                    selectedYear = currentYear;
                    }else if(month2=="jul"){
                    selectedYear = currentYear;
                    }else if(month2=="jun"){
                    selectedYear = currentYear;
                    }else if(month2=="may"){
                    selectedYear = currentYear;
                    }else if(month2=="apr"){
                    selectedYear = currentYear;
                    }else if(month2=="mar"){
                    selectedYear = currentYear;
                    }else if(month2=="feb"){
                    selectedYear = currentYear;
                    }else if(month2=="jan"){
                    selectedYear = currentYear;
                    } else if(month2=="dec"){
                    selectedYear = prev1Year;
                    }
                    setYear2(selectedYear);
                    }
                    else if(currMonthName=="dec"){
    
                      if(month2=="dec"){
                      selectedYear = prev1Year;
                      }else if(month2=="nov"){
                      selectedYear = prev1Year;
                      }else if(month2=="oct"){
                      selectedYear = prev1Year;
                      }else if(month2=="sep"){
                      selectedYear = prev1Year;
                      }else if(month2=="aug"){
                      selectedYear = prev1Year;
                      }else if(month2=="jul"){
                      selectedYear = prev1Year;
                      }else if(month2=="jun"){
                      selectedYear = prev1Year;
                      }else if(month2=="may"){
                      selectedYear = prev1Year;
                      }else if(month2=="apr"){
                      selectedYear = prev1Year;
                      }else if(month2=="mar"){
                      selectedYear = prev1Year;
                      }else if(month2=="feb"){
                      selectedYear = prev1Year;
                      } else if(month2=="jan"){
                      selectedYear = prev1Year;
                      }
                      setYear2(selectedYear);
                      }
      
  
  }
    




    const getTmDashboardTenantScoreBreakUp = async (tenantId,month,year,userId) => {
        await tmTenantsOverAlCredPirsDeroScoreRequest(tenantId,month,year,userId);
      
      };
      const drillDown = (y,x) => {
       //alert();
        setPirsEnabled(true);
    
       setScoreTypeValue(x);
       setMonthValue(y);
        if(y==3){
          setScoreTypeValue("PIRS");
            setPirsEnabled(true);
        }else if(y==2){
      
            setScoreTypeValue("CREDIT");
        }else if(y==3){
         
            setScoreTypeValue("DEROGATORY") ;
        }else{
            console.log("No type");
        }


      };

      function handleClickBack(event) {
  
       
         setShowBackNavigation(true);
    
       }
    
       function handleClickFundDashboard(event) {
         
       // setShowBackNavigation(true);
        history.push({ 
          pathname: '/dashboard'
         });
        
       }


    React.useEffect(() => {

 
      if(user.organizationId=="654b476a423c6401f20f16db"){
        setShowFundDetails(false);
      }else{
        setShowFundDetails(true);
      }
    //   if(source == "property"){
    
        setScoreLabels(false);
       //}
     
        getTmDashboardTenantScoreBreakUp(tenantId,monthNameValue1,year1,userId);
  
      }, [monthValue,scoreTypeValue]);

     



    const chartOptions = {

      
  

     series:tenantsOverAlCredPirsDeroScore,
      options: {

        yaxis: {
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 300, 
              
              style: {
                        fontSize: '16px',
                        fontWeight: 700,
                      }
                    }
                  },
                  xaxis: {
                     position: 'top',
                     labels: {
                      show: true,
                      
                        style: {
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  colors: ['black','black','black','black','black','black','black','black','black','black','black','black',],
                                  
                                }
                              }
                              },
        chart: {
          height: 350,
          type: ' ',
          toolbar:{
            show:false,
          },
          events: {
           
            click: (event, chartContext, config) => {
           
            
              setPirsEnabled(true);
             
           
              var el = event.target;
              var seriesIndex = parseInt(el.getAttribute("i"));
              var dataPointIndex = parseInt(el.getAttribute("j"));
              var seriesName = config.globals.seriesNames[seriesIndex];
           
              var scoreCategory;
              var monthNameVar;

              if(dataPointIndex==0){
                setYearAndMonth(prev11MonthName);
                setMonthNameValue(prev11MonthName);
                setPirsMonthNameValue(pirsPrev11MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }else if(dataPointIndex==1){
                setYearAndMonth(prev10MonthName);
                setMonthNameValue(prev10MonthName);
                setPirsMonthNameValue(pirsPrev10MonthName);
              
                setTimeout(() => {
         
                }, 3000);
                
              }else if(dataPointIndex==2){
                setYearAndMonth(prev9MonthName);
                setMonthNameValue(prev9MonthName);
                setPirsMonthNameValue(pirsPrev9MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }else if(dataPointIndex==3){
                setYearAndMonth(prev8MonthName);
                setMonthNameValue(prev8MonthName);
                setPirsMonthNameValue(pirsPrev8MonthName);
                setTimeout(() => {
         
                }, 3000);
              }else if(dataPointIndex==4){
                setYearAndMonth(prev7MonthName);
                setMonthNameValue(prev7MonthName);
                setPirsMonthNameValue(pirsPrev7MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }else if(dataPointIndex==5){
                setYearAndMonth(prev6MonthName);
                setMonthNameValue(prev6MonthName);
                setPirsMonthNameValue(pirsPrev6MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }else if(dataPointIndex==6){
                setYearAndMonth(prev5MonthName);
                setMonthNameValue(prev5MonthName);
                setPirsMonthNameValue(pirsPrev5MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }
              else if(dataPointIndex==7){
                setYearAndMonth(prev4MonthName);
                setMonthNameValue(prev4MonthName);
                setPirsMonthNameValue(pirsPrev4MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              }  else if(dataPointIndex==8){
                setYearAndMonth(prev3MonthName);
                setMonthNameValue(prev3MonthName);
                setPirsMonthNameValue(pirsPrev3MonthName);
                setTimeout(() => {
         
                }, 3000);
                
              } else if(dataPointIndex==9){
                setYearAndMonth(prev2MonthName);
                setMonthNameValue(prev2MonthName);
                setPirsMonthNameValue(pirsPrev2MonthName);
                console.log("y21"+year2);
                setTimeout(() => {
         
                }, 3000);
                
              }
              else if(dataPointIndex==10){
                setYearAndMonth(prev1MonthName);
                setMonthNameValue(prev1MonthName);
                setPirsMonthNameValue(pirsPrev1MonthName);
                // console.log("y21"+year2);
                setTimeout(() => {
         
                }, 3000);
                
              }
              else if(dataPointIndex==11){
                setYearAndMonth(currMonthName);
                setMonthNameValue(currMonthName);
                setPirsMonthNameValue(pirsCurrMonthName);
                // console.log("y22"+year2);
                setTimeout(() => {
         
                }, 3000);
              }
              
              else{
                  console.log("No type");
              }
        


      if(seriesName=="Industry Risk Score"){

        scoreCategory="PIRS";
        setShowPirsHeatMap(true);
        setShowDerogatoryHeatMap(false);
        setShowCreditReportHeatMap(false);
       
  
    }else if(seriesName=="Credit Score"){
    
        scoreCategory="CREDIT";
        setShowPirsHeatMap(false);
        setShowDerogatoryHeatMap(false);
        setShowCreditReportHeatMap(true) ;
    
    
  
    }else if(seriesName=="Derogatory Public Filings"){
        scoreCategory="DEROGATORY";
        setShowPirsHeatMap(false);
        setShowDerogatoryHeatMap(true);
        setShowCreditReportHeatMap(false); 
       
    }else{
        console.log("No type");
    }

 
              // history.push({
              //   pathname: '/admin/report/',
              //   state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId,tenantName:tenantName}
              // })
           //   if(source == "property"){
          //   history.push({
          //     pathname: '/manager/reportAssetmanager/',
          //     state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId}
          //   })
          // }else{
          //   history.push({
          //     pathname: '/admin/report/',
          //     state : { scoreType : seriesName, monthNumber:dataPointIndex,tenantId:tenantId}
          //   })
          // }
             
             //drillDown(config.seriesIndex,config.dataPointIndex); 
           // console.log(config);
           // config.seriesIndex;
            //config.dataPointIndex; 
            // console.log(chartContext);
            } 
          },
          series: [
            {
              name: "Series 1",
              data: [{
                x: 'Jan',
                y: 212
              }, {
                x: 'Feb',
                y: 290
              }, {
                x: 'Mar',
                y: 83
              }, {
                x: 'Apr',
                y: 320
              }]
            },
            {
              name: "Series 2",
              data: [{
                x: 'Jan',
                y: 430
              }, {
                x: 'Feb',
                y: 413
              }, {
                x: 'Mar',
                y: 45
              }, {
                x: 'Apr',
                y: 350
              }]
            }
          ]
        },
        plotOptions: {
          heatmap: {
            yaxis: {
              labels: {
                /**
                * Allows users to apply a custom formatter function to yaxis labels.
                *
                * @param { String } value - The generated value of the y-axis tick
                * @param { index } index of the tick / currently executing iteration in yaxis labels array
                */
                formatter: function(val, index) {
                  return val.toFixed(2);
                }
              }
            },
            
            radius: 0,
            useFillColorAsStroke: false,
            enableShades: true,
            shadeIntensity: 0.3,
            reverseNegativeShade: true,
            distributed: false,
            useFillColorAsStroke: false,
            colorScale: {
              show:true,
              ranges: [
                {
                  from: 401,
                  to: 500,
                  name: 'Low Risk',
                  color: '#05931f',
                  foreColor:'#000',
                },
                {
                  from: 301,
                  to: 400,
                  name: 'Moderately Low',
                  color: '#08eb32',
                  foreColor:'#000',
                },
                {
                  from: 201,
                  to: 300,
                  name: 'Medium',
                  color: '#FFE916',
                  foreColor:'#000',
                },
                {
                  from: 101,
                  to: 200,
                  name: 'Moderately High',
                  color: '#EFAD00',
                  foreColor:'#000',
                },
                {
                  from: 0,
                  to: 100,
                  name: 'High Risk',
                  color: '#ff0000',
                  foreColor:'#000',
                },
                {
                    from: 0,
                    to: 0,
                    name: 'NA',
                    color: '#81A594',
                    foreColor:'#00628B',
                  }
              ]
            }
          }
        },
        legend: {
            showForNullSeries: true,
            show : false,
            // formatter: function(seriesName, opts) {
            //     return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
            // }
          },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold"
          },
          formatter: function (val, opt) {
              
              if(val == 0){
            //return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
             return "NA";      
        }else
              return val;
          }
        },
        stroke: {
          width: 1
        },
        title: {
          text: ''
        },
      },
    
    
    };
 

    return (
      
      
<div>

{!showBackNavigation&&!showCreditReportHeatMap&&!showDerogatoryHeatMap&&!showPirsHeatMap&&
<GridContainer >


<GridItem xs={12} sm={12} md={12} style={{paddingLeft:'50px',paddingTop:'30px'}}>



   <Breadcrumbs aria-label="breadcrumb">

<Link   style={{color:megaColor}} href="#"  onClick={handleClickBack} className={classes.link}>
<Badge color="info"><b> <ArrowBackIosSharpIcon fontSize="inherit" className={classes.icon} />
Back</b></Badge> 
      </Link>
<Link   style={{color:megaColor}} href="#"  onClick={handleClickFundDashboard}>
{/* <HomeIcon fontSize="inherit" className={classes.icon} /> */}
<Badge color="info"><b> Dashboard</b></Badge> 
</Link>



</Breadcrumbs>


</GridItem>


         
<GridItem xs={12} sm={12} md={12}>
<h3 style={{ color: megaColor,paddingLeft:'20px' }}> <b>TenantTracker&trade; Score & Components by Month</b></h3>
<h4 style={{ color: megaColor,paddingLeft:'20px',fontSize:"18px"}}> <b>{tenantName}</b></h4>
<h4 style={{ color: megaColor,paddingLeft:'20px',fontSize:"15px"}}> <b>{tenantAddress},&nbsp;{city},&nbsp;{state},&nbsp;{zip}</b></h4>
{showFundDetails&& <h3 style={{ color: megaColor,fontSize:"18px",paddingLeft:'20px' }}> <b>Fund: {selectedFundName}</b></h3>}
{showFundDetails&& <h3 style={{ color: megaColor,fontSize:"18px",paddingLeft:'20px'}}> <b>Fund Manager: {selectedFundManagerName}</b></h3>}

</GridItem>
<GridItem xs={12} sm={5} md={3}>
     
</GridItem>

<GridItem xs={12} sm={7} md={9} className={classes.vertical} style={{paddingRight:"60px",textAlign:"right"}} >

     {/* <h6 style={{ color: megaColor, fontWeight:'bold', textAlign:'center' }}>Credit Rating</h6>  */}
       
       <div className={classes.buttonGroup} style={{paddingLeft:'28px'}}>
       <Button color="transparent" size="sm" className={classes.firstButton}>
       <b><h7 style={{color:'red'}}>High Risk</h7></b>
       </Button>

       <Button color="transparent" size="sm" className={classes.middleButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent" size="sm" className={classes.middleButton}>
        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b> 
       </Button>
       <Button color="transparent"  size="sm" className={classes.lastButton}>
       <b >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<h7 style={{color:'green'}}>Low Risk</h7></b>
       </Button>
     </div>
     <div className={classes.buttonGroup} style={{paddingLeft:'50px'}}>
       <Button color="danger" size="sm" className={classes.firstButton}>
         <b>0 - 100 </b>
       </Button>
       <Button color="warning" size="sm" className={classes.middleButton}>
       <b>101 - 200 </b> 
       </Button>
       <Button style = {{color:'white',backgroundColor:'#ffdf00'}} size="sm" className={classes.middleButton}>
       <b>201 - 300 </b>
       </Button>
       <Button style = {{color:'white',backgroundColor:'#32cd32'}}  size="sm" className={classes.lastButton}>
       <b>301 - 400 </b>
       </Button>
       <Button color="success"  size="sm" className={classes.lastButton}>
       <b>401 - 500 </b>
       </Button>
     </div>
    

</GridItem>



<GridItem xs={12} sm={12} md={12} align="center">
<div id="chart">
<Chart  options={chartOptions.options} series={chartOptions.series} type="heatmap" height={320}  />
</div>
    


</GridItem>
</GridContainer>
}

 {showBackNavigation&& <TMAMHeatMapPropertyTenant  fundId={fundId} fundName={fundName} propertyName={propertyName} propertyId={propertyId} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} month={month} year={year} category={category} backNavigation={true}  selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/>}

 {showCreditReportHeatMap && <TMAMCreditReportHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} pirsMonthNameValue={pirsMonthNameValue} month1={monthNameValue} month={month} year={year2} category={category} fundId={fundId} fundName={fundName} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} scoreType={"CREDIT"} tenantAddress={tenantAddress} city={city} state={state} zip={zip}  selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/>}

 {showDerogatoryHeatMap && <TMAMDerogatoryReportHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} pirsMonthNameValue={pirsMonthNameValue} month1={monthNameValue} month={month} year={year2} category={category} fundId={fundId} fundName={fundName} selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} scoreType={"DEROGATORY"} tenantAddress={tenantAddress} city={city} state={state} zip={zip}  selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/> }

 {showPirsHeatMap && <TMAMPirsReportHeatMap propertyName={propertyName} propertyId={propertyId} tenantId ={tenantId} tenantName={tenantName} month1={pirsMonthNameValue} year={year2} month={month} category={category} fundId={fundId} fundName={fundName}selectedRangeFrom={selectedRangeFrom} selectedRangeTo={selectedRangeTo} scoreType="PIRS" tenantAddress={tenantAddress} city={city} state={state} zip={zip}  selectedFundName={selectedFundName} selectedFundManagerName={selectedFundManagerName}/> }

</div>
    )

}

TMAMHeatMapTenantRisk.propTypes = {

    loading: PropTypes.bool.isRequired,
    tmTenantsOverAlCredPirsDeroScoreRequest: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = state => ({
    user: state.auth.loggedUser,
    tenantsOverAlCredPirsDeroScore: state.tenantmonitoring.tenantsOverAlCredPirsDeroScore,
    loading: state.tenantmonitoring.loading,
  });              
  
  const mapDispatchToProps = dispatch => bindActionCreators(
    {
  
      ...TMActions,
    },
    dispatch,
  );
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(TMAMHeatMapTenantRisk);
  